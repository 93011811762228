<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-for="news in itemsKabar"
        :key="news.title"
        cols="12"
        md="6"
        lg="6"
      >
        <div class="shadow-lg bg-body rounded">
          <b-card no-body>
            <b-card-body class="p-2">
              <b-card-title size="sm">
                {{ news.title }}
              </b-card-title>
              <b-card-sub-title>
                {{ news.kategori }}
              </b-card-sub-title>
            </b-card-body>
            <b-img
              fluid
              :src="news.urlThumbnail"
              alt="Card image cap"
            />
            <b-card-body>
              <b-card-text>
                {{ news.desc }}
              </b-card-text>
              <br>
              <b-button
                variant="danger"
                class="card-link"
                @click="hapusKabar(news.id)"
              >
                Hapus Kabar ini
              </b-button>
            </b-card-body>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BImg,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { db } from '@/firebase'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      itemsKabar: [],
      toggleKabar: false,
    }
  },
  created() {
    this.getKabar()
  },
  methods: {
    getKabar() {
      db.collection('appConfig').doc('mobile_mitra').collection('info_mitra')
        .get()
        .then(snapshot => {
          const arr = []
          snapshot.docs.forEach(doc => {
            arr.push({
              id: doc.id,
              title: doc.data().title,
              urlThumbnail: doc.data().url_thumbnail,
              desc: doc.data().desc,
              kategori: doc.data().kategori,
            })
          })
          this.itemsKabar = [...new Set(arr)]
        })
    },
    hapusKabar(id) {
      this.$swal({
        title: 'Hapus kabar mecare?',
        text: 'konfirmasi jika anda ingin menghapus data kabar',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          db.collection('appConfig').doc('mobile_mitra').collection('info_mitra').doc(id)
            .delete()
            .then(() => {
              this.getKabar()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil menghapus data kabar',
                  icon: 'TrashIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
